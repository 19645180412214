import './styles/App.css'; // Import your CSS
import {BrowserRouter, Link, Route, Routes, useLocation} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import LandingPage from "./LandingPage";
import ApplyForAtm from "./ApplyForAtm";
import Locations from "./Locations";

function ScrollToSection() {
    const location = useLocation();

    useEffect(() => {
        if (location.hash && document.querySelector(location.hash)) {
            window.scrollTo({
                behavior: 'smooth',
                top: document.querySelector(location.hash).offsetTop - 80,
            });
        }
    }, [location]);
}


function App() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // useEffect(() => {
    //     document.title = "MazumaATM";
    // }, []); // The empty array means this effect runs once on mount

    // Adjust menu state based on screen width
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 769) {
                setIsMenuOpen(false); // Automatically close the menu on wider screens
            }
        };

        // Add resize event listener
        window.addEventListener('resize', handleResize);

        // Clean up
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (

        <BrowserRouter>
            <ScrollToSection/>
            <header className="header">
                <button className="menu-toggle" onClick={toggleMenu}>☰</button>
            </header>
            <div className={`nav-container ${isMenuOpen ? "expanded" : ""}`}>
                {/* Hamburger Icon */}
                <nav>
                    <ul className={isMenuOpen ? "nav-links-mobile" : "nav-links"}>
                        <li><Link to="/#about" behavior="smooth">About Us</Link></li>
                        <li><Link to="/#what-is-bitcoin-atm" behavior="smooth">What is a Bitcoin ATM?</Link></li>
                        <li><Link to="/#benefits-clients" behavior="smooth">Benefits</Link></li>
                        <li><Link to="/#how-to-use" behavior="smooth">How to Use</Link></li>
                        <li><Link to="/#contact" behavior="smooth">Contact Us</Link></li>
                        <li><Link to="/#support" behavior="smooth">Support</Link></li>
                        <li><Link to="/#faq-item" behavior="smooth">FAQs</Link></li>
                        <li><Link to="/locations">Locations</Link></li>
                        <li>
                            <Link to="/apply" className="cta-button">Get an ATM in your store</Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/apply" element={<ApplyForAtm/>}/>
                <Route path="/locations" element={<Locations/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
