import React, {useState} from 'react';
import './styles/App.css'; // Import your CSS
import {Link} from 'react-router-dom';
import PaidIcon from '@mui/icons-material/Paid';
import LockIcon from '@mui/icons-material/Lock';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

function FAQItem({question, answer}) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div id="faq-item" className="faq-item" onClick={toggle}>
            <h3 id="faq-title" className="faq-title">
                {question}
                <span className="arrow">{isOpen ? ' ▲' : ' ▼'}</span>
            </h3>
            {isOpen && <p>{answer}</p>}
        </div>
    );
}

function FAQSection() {
    const faqs = [
        {
            question: "How do I use a Bitcoin ATM?",
            answer: "To use a Bitcoin ATM, simply scan your Bitcoin wallet's QR code, insert cash, and confirm the transaction. The Bitcoin will then be sent directly to your wallet."
        },
        {
            question: "Are Bitcoin ATMs safe to use?",
            answer: "Yes, Bitcoin ATMs are safe. Our machines are equipped with state-of-the-art security features to ensure your transactions are secure and private."
        },
        {
            question: "What fees do you charge for using a Bitcoin ATM?",
            answer: "Our fees are transparent and competitive. You'll see the exact fees before you confirm your transaction, so there are no surprises."
        },
        {
            question: "Do I need an ID to use a Bitcoin ATM?",
            answer: "For smaller transactions, under $1000, you won't need ID verification.  However, for larger amounts, we require a government-issued ID to comply with Know Your Customer (KYC) regulations."
        },
        {
            question: "What are the transaction limits for your Bitcoin ATMs?",
            answer: "With ID, there is no limit! However without ID, our daily transaction limits allow for both small and large purchases. You can buy up to CA$1000 worth of Crypto per day, and CA$5000 worth of Crypto per Week."
        },
        {
            question: "Can I sell Bitcoin for cash at your ATMs?",
            answer: "Unfortunately, not all our ATMs offer two-way conversion.  However, some locations do allow you to sell Bitcoin for cash. Please use our ATM locator on our website to find a machine that supports selling Bitcoin."
        },
        {
            question: "What happens if the Bitcoin ATM malfunctions during my transaction?",
            answer: "Our ATMs are reliable, but if a malfunction occurs, please don't hesitate to contact our 24/7 customer support team. We'll work diligently to resolve the issue and ensure you receive your Bitcoin or cash."
        },
        {
            question: "How do I find a Bitcoin ATM near me?",
            answer: "We have a convenient ATM locator on our website. Simply enter your address or postal code, and it will show you all the Bitcoin ATMs in your area."
        },
        {
            question: "What type of Bitcoin wallet do I need to use with your ATMs?",
            answer: "Our ATMs are compatible with most popular Crypto wallets. You'll just need a wallet that allows you to scan a QR code to receive the Crypto you are buying."
        },
        {
            question: "Is Bitcoin a good investment?",
            answer: "Bitcoin is a volatile and speculative investment. We recommend you do your own research before buying Bitcoin. We cannot provide financial advice."
        }
    ];


    return (
        <section id="faq" className="faq">
            <h2>Frequently Asked Questions (FAQ)</h2>
            {faqs.map(faq => (
                <FAQItem key={faq.question} question={faq.question} answer={faq.answer}/>
            ))}
        </section>
    );
}

function App() {
    return (
        <div className="App">
            {/* Scrollable Content Sections */}
            <main>
                <section id="about" className="about">
                    <img src={require('./assets/Fiat-ATM.png')} alt="About Mazuma" className="about-image"/>
                    <div>
                        <h2>About Mazuma</h2>
                        <p>Mazuma was born from a foundation of experience. For over 15 years, we've been a familiar
                            face in Ontario, operating a network of 350 fiat ATMs, many of which remain active today.
                            This journey has instilled in us a deep understanding of the ATM industry and the importance
                            of exceptional customer service.</p>

                        <p>Now, we're excited to leverage that experience to bring you a new frontier: cryptocurrency
                            through ATMs.</p>

                        <p>Mazuma isn't just about offering crypto ATMs – it's about bridging the gap between
                            traditional finance and the future of digital assets. We understand the potential of
                            cryptocurrency and are committed to making it accessible to everyone. We believe in
                            providing a secure, reliable, and user-friendly experience for those entering the crypto
                            world.</p>

                        <h3>Why Choose Mazuma?</h3>

                        <p>Unwavering Trust: Our 15-year track record with fiat ATMs speaks volumes about our commitment
                            to service and security. Proven Expertise: We possess in-depth knowledge of the ATM
                            industry, allowing us to translate that experience into a smooth crypto ATM experience.
                            Accessibility: We're making crypto ATMs readily available across Ontario, bringing this
                            innovative technology closer to you. User-Friendly Approach: We've designed our ATMs with an
                            intuitive interface, making it easy for users of all levels to navigate the world of
                            cryptocurrency. Mazuma represents a bridge between the familiar and the future. We're here
                            to guide you on your crypto journey, offering the same level of reliability and trust you've
                            come to expect from us.</p>
                    </div>
                </section>
                <section id="what-is-bitcoin-atm" className="what-is-bitcoin-atm">
                    <div>
                        <h2>What is a Bitcoin ATM?</h2>
                        <p>
                            A Bitcoin ATM (Automated Teller Machine) is a kiosk that allows you to buy and sell Bitcoin
                            with cash. It functions similarly to a traditional ATM, but instead of dealing with fiat
                            currency, it facilitates transactions with Bitcoin, a digital cryptocurrency.
                        </p>
                        <p>
                            Bitcoin ATMs offer a convenient way to access Bitcoin without needing a bank account or
                            going through a complex online exchange process. However, fees associated with Bitcoin ATMs
                            can be higher than those on online exchanges.
                        </p>
                    </div>
                    <img src={require('./assets/GB-Pro.png')} alt="Bitcoin ATM" className="atm-image"/>
                </section>
                <section id="benefits-clients" className="benefits">
                    <h2>Benefits of Using Mazuma Bitcoin ATMs</h2>
                    <ul>
                        <li>
                            <PaidIcon sx={{fontSize: 40, marginRight: 1}}/> {/* Material UI MoneyIcon */}
                            <h3>Convenience</h3>
                            <p>Buy and sell Bitcoin instantly with cash, without needing a bank account or
                                complex
                                online exchanges.</p>
                        </li>
                        <li>
                            <LockIcon sx={{fontSize: 40, marginRight: 1}}/> {/* Material UI SecurityIcon */}
                            <h3>Security</h3>
                            <p>Our ATMs are equipped with advanced security features to protect your
                                transactions.</p>
                        </li>
                        <li>
                            <FmdGoodIcon
                                sx={{fontSize: 40, marginRight: 1}}/> {/* Material UI LocationOnIcon */}
                            <h3>Accessibility</h3>
                            <p>We're expanding our network of ATMs across Ontario, bringing Bitcoin closer to
                                you.</p>
                        </li>
                        <li>
                            <ScheduleSendIcon
                                sx={{fontSize: 40, marginRight: 1}}/> {/* Material UI TouchAppIcon */}
                            <h3>User-Friendly</h3>  {/* Adjust margin-top for proper alignment */}
                            <p>Our ATMs are designed with an intuitive interface, making it easy to buy and sell
                                Bitcoin.</p>
                        </li>
                    </ul>
                </section>
                <section id="how-to-use" className="how-to-use">
                    <h2>How to Use a Mazuma Bitcoin ATM</h2>
                    <p>
                        Buying and selling Bitcoin with a Mazuma ATM is easy! Follow these simple steps:
                    </p>
                    <ol>
                        <li>Easily locate a Mazuma Bitcoin ATM near you using our <a href="/locations">map</a></li>
                        <li>Approach the ATM and follow the on-screen instructions.</li>
                        <li>
                            Select the Cryptocurrency to buy depending on your desired transaction.
                        </li>
                        <li>
                            For buying Bitcoin, scan your Bitcoin wallet QR code.
                            <ul>
                                <li>
                                    Dont have a QR code? No problem! Use <a
                                    href="https://www.qr-code-generator.com/solutions/text-qr-code/" target={"_blank"}
                                    rel="noreferrer">this tool</a> to generate one.
                                </li>
                            </ul>
                        </li>
                        <li>Insert cash following the ATM's instructions.</li>
                        <li>
                            Confirm the transaction details and receive your Bitcoin instantly.
                        </li>
                    </ol>
                    {/* Add important notes or disclaimers here (e.g., transaction fees) */}
                </section>
                <section id="contact" className="contact">
                    <h2>Contact Us</h2>
                    <p>
                        Have questions? We'd love to hear from you! Here are some ways to get in touch:
                    </p>
                    <ul>
                        <li>Email: <a href="mailto:support@mazuma.me">support@mazuma.me</a></li>
                        <li>Phone: <a href="tel:+18776298621">1-877-MAZUMA1</a> or <a href="tel:+18776298621">1-877-629-8621</a></li>
                    </ul>
                </section>
                <section id="support" className="support">
                    <h2>Support</h2>
                    <p>
                        Need help using a Mazuma Bitcoin ATM? We have resources to assist you:
                    </p>
                    <ul>
                        <li>
                            <Link to="#faq-item">FAQ (Frequently Asked Questions)</Link>
                        </li>
                        <li>
                            <Link to="#contact">Contact Us</Link>
                        </li>
                    </ul>
                </section>
                <section id="news" className="news">
                    <h2>News</h2>
                    <p>
                        Stay up-to-date on the latest news from Mazuma and the world of Bitcoin!
                    </p>
                    {/* Add a news feed or links to relevant articles here */}
                </section>
                <FAQSection/>
            </main>
        </div>
    );
}

export default App;
