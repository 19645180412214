import React from 'react';
import mapPlaceholder from './assets/map.png'; // Update with your map image path
import './styles/Locations.css';

function Locations() {
    return (
        <div className="locations-page">
            <h1>Our Locations Across Canada</h1>
            <p>We are proud to operate over 100 Bitcoin ATM machines across Canada and are steadily growing to reach more communities.</p>
            <div className="map-container">
                <img src={mapPlaceholder} alt="Map of Our Locations" className="map-image"/>
                <div className="coming-soon">Coming Soon</div>
            </div>
        </div>
    );
}

export default Locations;
