import React, {useEffect, useState} from 'react';
import './styles/ApplyForAtm.css';
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BarChartIcon from "@mui/icons-material/BarChart";
import NoAccountsIcon from "@mui/icons-material/NoAccounts"; // Import your CSS

function ApplyForAtm() {
    const [formData, setFormData] = useState({
        storeName: '',
        street: '',
        city: '',
        province: '',
        postalCode: '',
        country: '',
        storeType: '',
        merchantName: '',
        phone: '',
        email: '',
    });
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        // Handle form submission, e.g., sending data to an API
    };

    useEffect(() => {
        if (submitted) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [submitted]);

    if (submitted) {
        return <div className="submission-message">We have received your application and will get in touch shortly.
            Thank you!</div>;
    }

    return (
        <main>
            <section id="benefits-merchant" className="benefits">
                <h2>Benefits of Hosting a Mazuma Bitcoin ATM at Your Store</h2>
                <ul>
                    <li>
                        <PeopleAltIcon sx={{
                            fontSize: 40,
                            marginRight: 1
                        }}/> {/* Suggested Material UI Icon for foot traffic */}
                        <h3>Boost Foot Traffic</h3>
                        <p>Attract more customers to your store. A Bitcoin ATM on your premises draws in
                            cryptocurrency users, increasing foot traffic and new potential customers.</p>
                    </li>
                    <li>
                        <MonetizationOnIcon sx={{
                            fontSize: 40,
                            marginRight: 1
                        }}/> {/* Suggested Material UI Icon for monthly income */}
                        <h3>Guaranteed Monthly Income</h3>
                        <p>Earn a consistent revenue, ensuring a
                            steady income stream with no effort on your part.</p>
                    </li>
                    <li>
                        <BarChartIcon sx={{
                            fontSize: 40,
                            marginRight: 1
                        }}/> {/* Suggested Material UI Icon for profitability */}
                        <h3>Top Profit per Square Foot</h3>
                        <p>Maximize your space's earning potential. Bitcoin ATMs represent one of the most
                            profitable uses of retail square footage, surpassing many traditional products.</p>
                    </li>
                    <li>
                        <NoAccountsIcon sx={{
                            fontSize: 40,
                            marginRight: 1
                        }}/> {/* Suggested Material UI Icon for no hidden fees */}
                        <h3>No Hidden Fees</h3>
                        <p>Enjoy transparent operations without any hidden fees. We ensure you know exactly what
                            you're earning, making financial planning straightforward.</p>
                    </li>
                </ul>
            </section>
            <div className="application-form">
                <form onSubmit={handleSubmit}>
                    <h2>Apply for a Bitcoin ATM at Your Store</h2>

                    <fieldset>
                        <legend>Your Info</legend>
                        <label htmlFor="merchantName">Your Name:</label>
                        <input type="text" id="merchantName" name="merchantName" value={formData.merchantName}
                               onChange={handleChange} required/>

                        <label htmlFor="phone">Phone Number:</label>
                        <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange}
                               required/>

                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange}
                               required/>
                    </fieldset>

                    <fieldset>
                        <legend>Store Info</legend>
                        <label htmlFor="storeName">Store Name:</label>
                        <input type="text" id="storeName" name="storeName" value={formData.storeName}
                               onChange={handleChange} required/>

                        <label htmlFor="street">Location - Street:</label>
                        <input type="text" id="street" name="street" value={formData.street} onChange={handleChange}
                               required/>

                        <label htmlFor="city">City:</label>
                        <input type="text" id="city" name="city" value={formData.city} onChange={handleChange}
                               required/>

                        <label htmlFor="province">Province:</label>
                        <input type="text" id="province" name="province" value={formData.province}
                               onChange={handleChange}
                               required/>

                        <label htmlFor="postalCode">Postal Code:</label>
                        <input type="text" id="postalCode" name="postalCode" value={formData.postalCode}
                               onChange={handleChange} required/>

                        <label htmlFor="country">Country:</label>
                        <input type="text" id="country" name="country" value={formData.country} onChange={handleChange}
                               required/>

                        <label htmlFor="storeType">Store Type:</label>
                        <input type="text" id="storeType" name="storeType" value={formData.storeType}
                               onChange={handleChange} required/>
                    </fieldset>

                    <button type="submit" className="submit-button">Submit Application</button>
                </form>
            </div>
        </main>
    );
}

export default ApplyForAtm;
